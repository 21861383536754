<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="container-fluid p-0">
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header>
    <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
  </header>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div>
    <div id="progressBar" *ngIf="!isProgressHidden">
      <div class="container">
        <div class="container">
          <div class="row" style="margin-bottom: 10px;">
            <div class="col-md-10 col-7">
              <span class="progressBarLabel" *ngIf="isProgress">{{progressTitle}}</span>
            </div>
            <div class="col-md-2 col-5">
              <div class="viewHide">
                <div class="btn-group btn-group-toggle btn-group-small float-right" ngbRadioGroup name="radioBasic" [(ngModel)]="isProgress" (ngModelChange)="changeViewProgress()">
                  <label ngbButtonLabel class="btn-outline-primary">
                    <input ngbButton type="radio" [value]="true">View
                  </label>
                  <label ngbButtonLabel class="btn-outline-primary">
                    <input ngbButton type="radio" [value]="false">Hide
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isProgress">
            <div class="col-md-12">
              <div class="progress">
                <div class="progress-bar" [style.width.%]="progress" role="progressbar" [aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <router-outlet></router-outlet>
    <!-- ============================================================== -->
    <!-- End Start Page Content -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Container fluid  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Purple footer -->
  <div id="purplefooter" class="p-4" style="background-color: #f5e3ff;" *ngIf="(progress > 39 && progress < 61) || (progress > 39 && progress < 90 && quote && (quote.is_vet_fee_cover || quote.is_trailer_cover))">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img src="assets/images/equario-logo.png" class="equario-logo w-25">
        </div>
      </div>
      Vet Fees and Horse Trailer Insurance policies are underwritten by Equario Insurance (Guernsey) Limited and you are
      now on a Guernsey server, hosted on behalf of Equario Insurance (Guernsey) Limited.
      <br /><br />
      Equario Insurance (Guernsey) Limited (EIG) is registered in Guernsey under Company Number 69867 and licensed and
      regulated by the Guernsey Financial Services Commission (<a href="https://www.gfsc.gg/" target="_blank">www.gfsc.gg</a>)to
      provide certain insurance products.
      <br /><br />
      As this cover is provided from Guernsey, the Channel Islands Financial Ombudsman (<a href="https://www.ci-fo.org/"
        target="_blank">www.ci-fo.org</a>) would deal with
      any complaints, rather than the UK Financial Ombudsman Service.
      <br /><br />
      Harry Hall International Limited and Equario Insurance (Guernsey) Limited are entities which have the same
      ultimate beneficial owner, the International Riding Company Limited.
      <br /><br />
      Complaints procedures will be provided along with policy documentation, however these are also <a href="https://harryhall.com/help-contact-us#tab-pnwjprr" target="_blank">accessible here</a>.
    </div>
  </div>

  <div id="footer">
    <div class="container">
      <div class="row no-gutters">
        <div id="reviewsio" class="col-md-4 text-center">
          <div id="badge-230" style="max-width:230px;"></div>
        </div>
        <div class="col-md-8">
          Call us on <a href="tel:01274 711011">01274 711011</a>
          <br /><br />
          It is important that you respond accurately, if you don't it may affect the amount that you pay and the claims
          that we pay. If incorrect responses have been provided we may reduce or not make a claim payment or cancel
          your policy and we may not refund your premiums. Your responses must be correct for each person, animal or
          vehicle you want to insure.
          <br /><br />
          *One Club Membership shopping vouchers for HarryHall.com are subject to terms and availability and will be
          delivered in a confirmation email to the email address provided. Full terms for the voucher will be provided
          in the email.
          <br /><br />
          <a href="https://harryhall.com/terms-and-conditions" target="_blank">Terms & Conditions</a>
          &nbsp;&nbsp;&nbsp;
          <a href="https://support.harryhall.com/portal/kb/articles/privacy-policy-21-11-2019" target="_blank">Privacy Policy</a>
          &nbsp;&nbsp;&nbsp;
          <a href="https://harryhall.com/help-contact-us" target="_blank">Contact Us</a>
          <br /><br />
          &copy; Copyright Harry Hall International Limited.
          <br /><br />
          Equario Insurance (Guernsey) Limited, is registered in Guernsey under Registration Number 69867 and is
          regulated by the Guernsey Financial Services Commission. Equario Insurance (Guernsey) Limited’s registered
          office is Level 5, Mill Court, La Charroterie, St Peter Port, Guernsey GY1 1EJ.
          <br /><br />
          <span>Harry Hall International Limited is authorised and regulated by the Financial Conduct Authority (FCA) our firm reference number is 968047. You may check this on the Financial Services Register by <a href="https://register.fca.org.uk/s/">visiting the FCA website click here</a></span>
        </div>
      </div>
    </div>
  </div>
  <!-- ============================================================== -->
  <!-- End footer -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
