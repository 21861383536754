import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(router: Router, private loaderService: LoaderService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url) {
          if (event.url.startsWith('/customer')) {
            window.location.href = environment.link + '/#' + event.url;
          } else if (event.url.startsWith('/pages/login')) {
            window.location.href = environment.link + '/#' + event.url;
          } else if (!!event.url && event.url.startsWith('/pages/resetpassword')) {
            window.location.href = environment.link + '/#' + event.url;
          } else if (!!event.url && event.url.startsWith('/authentication')) {
            window.location.href = environment.link + '/#' + event.url;
          }
        }
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        if (event.url != '/pages/maintenance') {
          this.loaderService.isShoMmaintenance(false);
        }
      }
    });
  }

  ngOnInit() {

  }
}