<div id="top-menu">
  <div class="container">
    <div class="row no-gutters">
      <div class="col text-right">
        <ul style="display: inline;">
          <li class="top-menu-item"><a href="{{portalUrl}}/#/pages/login">Login</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div id="main-menu">
  <div class="container">
    <div id="desktopMenu">
      <div class="row no-gutters">
        <div class="col-md-3 col-sm-6 bigName">
         <a href="/">HARRY HALL</a>
        </div>
        <div class="col-md-9 main-menu text-right">
          <span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
        </div>
      </div>
    </div>
    <div id="mobileToggle">
      <div class="row no-gutters">
        <div class="col-8 bigName">
          HARRY HALL
        </div>
        <div class="col-4 main-menu text-right">
          <span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
        </div>
      </div>
    </div>
  </div>
</div>