import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  cart: any;
  current_quote: any;
  current_mc: any;
  vet_cover_horse_total: any;
  cost: any;
  GTM: any;
  private quoteSource = new BehaviorSubject(0);
  private quoteHorsesSource = new BehaviorSubject(0);
  private mcSource = new BehaviorSubject(0);
  private costSource = new BehaviorSubject(0);
  private progSource = new BehaviorSubject(0);
  private gtmSource = new BehaviorSubject(0);
  private cartSource = new BehaviorSubject(0);
  quote = this.quoteSource.asObservable();
  quote_horses = this.quoteHorsesSource.asObservable();
  mc = this.mcSource.asObservable();
  c = this.costSource.asObservable();
  ct = this.cartSource.asObservable();
  pr = this.progSource.asObservable();
  gt = this.gtmSource.asObservable();
  constructor() { }
  setQuote(quote) {
    this.current_quote = quote;
  }
  getQuote() {
    return this.current_quote;
  }
  changeQuotes(quote: any) {
    this.quoteSource.next(quote)
  }
  setCart(ct) {
    this.cart = ct;
  }
  getCart() {
    return this.cart;
  }
  changeCarts(ct: any) {
    this.cartSource.next(ct)
  }
  setMembershipCost(mc) {
    this.current_mc = mc;
  }
  getMembershipCost() {
    return this.current_mc;
  }
  changeMembershipCost(mc: any) {
    this.mcSource.next(mc)
  }
  getVetCoverHorses() {
    return this.vet_cover_horse_total;
  }
  setVetCoverHorses(horses) {
    this.vet_cover_horse_total = horses;
  }
  changeVetCoverHorses(horses: any) {
    this.quoteHorsesSource.next(horses)
  }
  setCost(c) {
    this.cost = c;
  }
  getCost() {
    return this.cost;
  }
  changeCost(c: any) {
    this.costSource.next(c)
  }
  updateProgress(p: any) {
    this.progSource.next(p)
  }
  setGTM(data) {
    this.GTM = data;
  }
  getGTM() {
    return this.GTM;
  }
  changeGTM(data: any) {
    this.gtmSource.next(data)
  }
}
