import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})

export class NavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  portalUrl: string;

  constructor() {
    this.portalUrl = environment.link;
  }
}
