import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

declare const reviewsBadgeModern: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit, AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};
  progressTitle = "You're just a few clicks away from getting covered";
  quote: any;

  constructor(public router: Router, public apiService: ApiService, public dataService: DataService, config: NgbPopoverConfig) {
    config.container = 'body';
    config.placement = 'top';
    config.triggers = 'click';
  }

  public innerWidth: number = 0;
  public defaultSidebar: string = '';
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = 'full';
  isProgress = true;
  isProgressHidden = true;
  progress = 10;

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    this.isProgress = localStorage.getItem('progress') == 'false' ? false : true;
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();

    if (environment.production) {
      reviewsBadgeModern('badge-230', {
        store: 'harry-hall',
        primaryClr: '#202440',
        starsClr: '#0076a5'
      });
    }

    this.apiService.getMembershipCost()
      .subscribe(
        (res: any) => {
          this.dataService.setMembershipCost(res);
          this.dataService.changeMembershipCost(1);
        });

    this.dataService.quote.subscribe(q => {
      if (q) {
        this.quote = this.dataService.getQuote();
      }
    });
  }

  ngAfterViewInit() {
    this.dataService.pr.subscribe(p => {
      if (p > 0) {
        setTimeout(() => {
          this.progress = p;
          this.setProgressTitle(p);
        }, 500);
        this.isProgressHidden = false;
      } else {
        this.isProgressHidden = true;
      }
    });
  }

  @HostListener('window:resize', ['$event'])

  onResize() {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'mini-sidebar';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }

  changeViewProgress() {
    localStorage.setItem('progress', this.isProgress.toString());
  }

  private setProgressTitle(progress) {
    switch (progress) {
      case 20:
        // code block
        break;
      case 30:
        this.progressTitle = "That's the first hurdle sorted, not far to go";
        break;
      case 40:
        this.progressTitle = "Your policy is only minutes away!";
        break;
      case 50:
        this.progressTitle = "You're just a few clicks away from cover";
        break;
      case 60:
        this.progressTitle = "You're just a few clicks away from cover";
        break;
      case 70:
        this.progressTitle = "Last few steps now!";
        break;
      case 80:
        this.progressTitle = "The legal bits";
        break;
      case 90:
        this.progressTitle = "Awesome! It's finishing time";
        break;
      case 100:
        this.progressTitle = "Finished!";
        this.isProgressHidden = true;
        break;
      default:
      // code block
    }
  }
}
